@define-mixin bookmarkTabsVariant $backgroundColor, $textColor, $mobileTabColor, $desktopTabColor, $hoverColor,
    $mobileSelectedColor, $desktopSelectedColor {
    & .Tabs {
        background: $backgroundColor;

        @media (--desktop) {
            background: initial;
        }
    }

    & .Tab {
        background: $mobileTabColor;
        color: $textColor;

        @media (--desktop) {
            background: $desktopTabColor;
        }

        &:hover:not(.Tab--selected) {
            background-color: $hoverColor;
        }

        &.Tab--selected {
            background-color: $mobileSelectedColor;

            @media (--desktop) {
                background-color: $desktopSelectedColor;
            }
        }
    }
}

.BookmarkTabs {
    display: flex;
    padding: 0 var(--wrapperHorizontalPadding);
    overflow-x: auto;

    @media (--desktop) {
        display: block;
        padding: 0;
        overflow-x: visible;
    }
}

.Tabs {
    display: flex;
    flex-shrink: 0;
    min-width: 100%;

    @media (--desktop) {
        display: block;
    }
}

.Tab {
    @mixin buttonTransitionBehavior background-color;
    @mixin p30;

    @mixin onFocus {
        @mixin focusVisible 0;
        position: relative;
    }
    padding: 12px 16px;
    border: none;
    flex-grow: 1;

    @media (--desktop) {
        padding: 12px 32px;
    }
}

.TabLabel {
    @mixin buttonTransitionBehavior text-shadow;
}

.Tab--selected {
    & .TabLabel {
        @mixin visuallyBold;
    }
}

.Variant--dark {
    @mixin bookmarkTabsVariant var(--blackLighten10Color), var(--whiteBaseColor), var(--blackLighten10Color),
        var(--blackBaseColor), var(--blackLighten12Color), var(--blackLighten20Color), var(--blackLighten10Color);
}

.Variant--light {
    @mixin bookmarkTabsVariant var(--blackLighten50Color), var(--blackBaseColor), var(--blackLighten50Color),
        var(--blackLighten50Color), var(--blackLighten40Color), var(--whiteBaseColor), var(--blackLighten60Color);
}

.Variant--rounded {
    & .Tabs {
        @mixin themeRoundedCornersMedium;
    }

    & .Tab {
        @mixin buttonTransitionBehavior single-arg(background-color, border-radius);
        @mixin themeRoundedCornersMedium;

        @media (--desktop) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.ContainerWithTabBottomHack {
    & .Tab {
        @media (--firefox) {
            @media (--desktop) {
                &:first-child::after {
                    transition: inherit;
                }
            }
        }

        @media (--desktop) {
            @mixin onFocus {
                z-index: 1;

                &:first-child::after {
                    content: none;
                }
            }
            position: relative;

            &:first-child::after {
                content: '';
                position: absolute;
                background: inherit;
                width: 100%;
                height: 16px;
                bottom: -16px;
                left: 0;
            }
        }
    }
}
