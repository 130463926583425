@define-mixin lineUnder $bottomSpacing: 32px, $width: 70px, $height: 4px, $color: var(--primaryHKBaseColor) {
    position: relative;
    display: inline-block;
    padding-bottom: $bottomSpacing;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: $height;
        width: $width;
        background-color: $color;
    }
}

@define-mixin resetLineUnder {
    padding-bottom: 0;

    &::after {
        content: none;
    }
}

@define-mixin ellipsisOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@define-mixin visuallyBold {
    text-shadow:
        0.025em 0 0 currentColor,
        -0.025em 0 0 currentColor;
}
