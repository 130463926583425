.BookmarkContainer {
    @media (--desktop) {
        padding-bottom: 32px;
    }
}

.Wrapper {
    @media (--desktop) {
        @mixin wrapper;
    }
}

.UpperSection {
    display: flex;
    flex-direction: column-reverse;
    padding: 24px 0;

    @media (--desktop) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
    }
}

.UpperDescription {
    @mixin p30;
    display: block;
    text-align: center;
    padding: 0 var(--wrapperHorizontalPadding);
    margin-bottom: 16px;

    @media (--desktop) {
        padding: 0;
        margin-bottom: 8px;
        align-self: end;
    }
}

.Content {
    padding: 24px var(--wrapperHorizontalPadding);

    @media (--desktop) {
        @mixin horizontalPadding 32px;
    }
}

.HiddenContent {
    display: none;
}

.Variant--dark {
    background: var(--blackBaseColor);
    color: var(--whiteBaseColor);

    & .UpperDescription {
        color: var(--blackLighten30Color);
    }

    & .Content {
        background: var(--blackLighten10Color);
    }
}

.Variant--light {
    background: var(--blackLighten60Color);
    color: var(--blackBaseColor);

    @media (--desktop) {
        background: var(--blackLighten50Color);
    }

    & .UpperDescription {
        color: var(--blackLighten20Color);
    }

    & .Content {
        background: var(--blackLighten60Color);
    }
}

.Variant--rounded {
    & .Content {
        @media (--desktop) {
            @mixin themeRoundedCornersMedium;
            position: relative;
        }
    }
}
