@define-mixin blockVerticalPadding $mobilePadding: 32px, $tabletPadding: 48px {
    @mixin verticalPadding $mobilePadding;

    @media (--tablet) {
        @mixin verticalPadding $tabletPadding;
    }
}

@define-mixin verticalPadding $top, $bottom: $top {
    padding-top: $top;
    padding-bottom: $bottom;
}

@define-mixin horizontalPadding $left, $right: $left {
    padding-left: $left;
    padding-right: $right;
}

@define-mixin heroBlockHeight {
    min-height: calc(100vh - var(--headerMobileHeight));

    @media (--tablet) {
        min-height: 720px;
    }

    @media (--desktopHD) {
        min-height: 900px;
    }
}

@define-mixin lost-offset-right $offset {
    margin-right: calc((100% + var(--gutterWidth)) * $offset) !important;
}

@define-mixin safariModalFullHeight {
    @media (--mobileOnly) {
        height: 100vh;
    }
}

/* RICHTEXT */

@define-mixin calloutWrapperLayoutInRichtext {
    @media (--tablet) {
        --layoutContentOffset: 0;
        --layoutContentSpan: 6;
    }

    @media (--desktopHD) {
        --layoutContentSpan: 4;
    }
}

/* STUDY PAGE */

@define-mixin studyPageSectionPadding {
    @mixin verticalPadding 80px;
}

/* PAGE GRID */

@define-mixin fullPageGrid {
    --layoutGridOffset: 0;
    --layoutGridSpan: 12;
}
