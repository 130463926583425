.ComboSelector {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (--tablet) {
        flex-direction: row;
        gap: 24px;
    }
}

.Label {
    @mixin p20;
    display: flex;
    align-items: center;
    min-width: 128px;
    font-weight: 600;
}

.Variant--dark {
    & .Label {
        color: var(--blackBaseColor);
    }
}

.Variant--light {
    & .Label {
        color: var(--whiteBaseColor);
    }
}
