@define-mixin accordionExpanderVariant {
    @mixin h50;
    font-family: var(--serifFontFamily);
    font-weight: 600;
}

.AccordionExpander {
    @mixin onFocus {
        @mixin focusVisible 2px;
    }
    width: 100%;

    &:disabled,
    &:disabled:hover {
        cursor: default;
        opacity: 0.4;
    }
}

.ButtonContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Content {
    @mixin hyphenateWords;
    flex: 1;
    min-width: 0;
    text-align: left;
}

.Icon {
    @mixin fixedRatioSvgIcon 24px;
}

.Variant--solid {
    &.AccordionExpander {
        @mixin accordionExpanderVariant;
        padding: 16px 32px;

        @media (--tablet) {
            padding: 16px 24px;
        }
    }

    &.Variant--light {
        background-color: var(--whiteBaseColor);

        & .Icon {
            @mixin svgIconColor var(--primaryHKBaseColor);
        }
    }
}

.Variant--outline {
    &.AccordionExpander {
        @mixin accordionExpanderVariant;
        padding: 16px 0;
    }

    &.Variant--dark,
    &.Variant--grey {
        &.AccordionExpander {
            background-color: unset;
        }
    }

    &.Variant--dark.Variant--hidden {
        &.AccordionExpander {
            border-bottom: 1px solid var(--blackLighten10Color);
        }
    }

    &.Variant--grey.Variant--hidden {
        &.AccordionExpander {
            border-bottom: 1px solid var(--blackLighten50Color);
        }
    }

    &.Variant--light,
    &.Variant--white {
        & .Icon {
            @mixin svgIconColor var(--whiteBaseColor);
        }

        &.AccordionExpander {
            color: var(--whiteBaseColor);
            background-color: unset;
        }

        &.Variant--hidden {
            &.AccordionExpander {
                border-bottom: 1px solid var(--whiteBaseColor);
            }
        }
    }
}

.Variant--rounded {
    &.AccordionExpander {
        @mixin themeRoundedCornersMedium;
    }
}

.Variant--clear {
    /* todo. Move here from main styles (where this variant is defined as default or remove the type if no longer needed). */
}

.Variant--darkGrey {
    /* todo. Move here from main styles (where this variant is defined as default or remove the type if no longer needed). */
}
