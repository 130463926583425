$outlineBorderSize: 1px;

@define-mixin pillTagTextColor $textColor {
    color: $textColor;

    & :global(.SvgIcon) {
        @mixin svgIconColor $textColor;
    }
}

@define-mixin pillTagColor $color {
    border: $outlineBorderSize solid $color;

    &:hover {
        border-bottom-width: $outlineBorderSize;
    }

    &.Variant--solid {
        background: $color;

        &.Variant--link {
            @mixin animatePropertySlower box-shadow;

            &:hover {
                box-shadow:
                    0 0 50px rgb(var(--blackBaseRgb) / 20%),
                    0 0 0 2px $color;
            }
        }
    }
}

@define-mixin pillTagSize $size, $radius: $size {
    padding: $size calc($size * 2);
    border-radius: $radius;
}

.PillTag {
    text-align: center;

    & :global(.SvgIcon) {
        @mixin fixedRatioSvgIcon 24px;
    }

    &.Variant--basic {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        &.Variant--xSmall {
            gap: 4px;
        }
    }

    &.Variant--link {
        &.Variant--link {
            @mixin onFocus {
                @mixin focusVisible 0;
            }

            & .LinkLabel {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }

    &.Variant--outline {
        /* Empty */
    }

    &.Variant--small {
        @mixin pillTagSize calc(8px - $outlineBorderSize), 20px;
    }

    &.Variant--xSmall {
        @mixin pillTagSize calc(4px - $outlineBorderSize), 24px;
    }

    &.Variant--normal {
        @mixin pillTagSize calc(12px - $outlineBorderSize), 24px;
    }

    &.Variant--responsiveFromXSmallToSmall {
        @mixin p40;
        padding: 4px 16px;
        border-radius: 20px;

        @media (--tablet) {
            @mixin pillTagSize 8px, 20px;
        }
    }

    &.Variant--defaultFont {
        @mixin pillTagTextColor var(--blackBaseColor);
    }

    &.Variant--lightFont {
        @mixin pillTagTextColor var(--whiteBaseColor);
    }

    &.Variant--redFont {
        @mixin pillTagTextColor var(--primaryHKDarken20Color);
    }

    &.Variant--brightRedFont {
        @mixin pillTagTextColor var(--primaryHKBaseColor);
    }

    &.Variant--turquoiseFont {
        @mixin pillTagTextColor var(--support02HKDarken20Color);
    }

    &.Variant--greyFont {
        @mixin pillTagTextColor var(--blackLighten17Color);
    }

    &.Variant--blueFont {
        @mixin pillTagTextColor var(--actionBlueBaseColor);
    }

    &.Variant--lightBlueFont {
        @mixin pillTagTextColor var(--mkBlueLighten10Color);
    }

    &.Variant--greenFont {
        @mixin pillTagTextColor var(--mkGreenBaseColor);
    }

    &.Variant--lightGreenFont {
        @mixin pillTagTextColor var(--mkGreenLighten20Color);
    }

    &.Variant--yellowFont {
        @mixin pillTagTextColor var(--mkYellowBaseColor);
    }

    &.Variant--lightGreyFont {
        @mixin pillTagTextColor var(--mkGrayBaseColor);
    }

    &.Variant--darkRedFont {
        @mixin pillTagTextColor var(--mkRedBaseColor);
    }

    &.Variant--default {
        @mixin pillTagColor var(--whiteBaseColor);
    }

    &.Variant--dark {
        @mixin pillTagColor var(--blackBaseColor);
    }

    &.Variant--red {
        @mixin pillTagColor var(--primaryHKDarken20Color);
    }

    &.Variant--brightRed {
        @mixin pillTagColor var(--primaryHKBaseColor);
    }

    &.Variant--turquoise {
        @mixin pillTagColor var(--support02HKDarken20Color);
    }

    &.Variant--grey {
        @mixin pillTagColor var(--blackLighten17Color);
    }

    &.Variant--blue {
        @mixin pillTagColor var(--actionBlueBaseColor);
    }

    &.Variant--lightBlue {
        @mixin pillTagColor var(--mkBlueLighten10Color);
    }

    &.Variant--green {
        @mixin pillTagColor var(--mkGreenBaseColor);
    }

    &.Variant--lightGreen {
        @mixin pillTagColor var(--mkGreenLighten20Color);
    }

    &.Variant--yellow {
        @mixin pillTagColor var(--mkYellowBaseColor);
    }

    &.Variant--lightGrey {
        @mixin pillTagColor var(--mkGrayBaseColor);
    }

    &.Variant--darkRed {
        @mixin pillTagColor var(--mkRedBaseColor);
    }
}
