@define-mixin selectSize $paddingBlock, $paddingInline {
    $iconSize: 24px;

    & .Native {
        padding: $paddingBlock calc($paddingInline + $iconSize) $paddingBlock $paddingInline;
    }

    & .Icon {
        right: $paddingInline;
        top: $paddingBlock;
    }
}

@define-mixin selectVariant $background, $borderColor, $textColor, $fontWeight: initial {
    color: $textColor;

    & .Inner {
        border-color: $borderColor;
    }

    & .Native {
        color: $textColor;
        background: $background;
        font-weight: $fontWeight;
    }

    & .Icon {
        @mixin svgIconColor $textColor;
    }
}

.Label {
    @mixin p30;
    margin-bottom: 12px;
    display: block;
}

.Inner {
    position: relative;
    border: 1px solid;
}

.Native {
    @mixin p30;

    @mixin onFocus {
        @mixin focusVisible;
    }
    border: none;
    border-radius: 0;
    appearance: none;
    width: 100%;
}

.Icon {
    @mixin fixedRatioSvgIcon 24px;
    position: absolute;
    pointer-events: none;
}

.Variant--rounded {
    & .Inner,
    & .Native {
        @mixin themeRoundedCornersMedium;
    }
}

.Variant--medium {
    @mixin selectSize 12px, 16px;
}

.Variant--large {
    @mixin selectSize 16px, 24px;
}

.Variant--light {
    @mixin selectVariant var(--whiteBaseColor), var(--blackLighten10Color), var(--blackBaseColor);
}

.Variant--dark {
    @mixin selectVariant var(--blackLighten12Color), var(--blackLighten30Color), var(--whiteBaseColor), 600;
}

.Variant--greyOutline {
    @mixin selectVariant var(--whiteBaseColor), var(--blackLighten40Color), var(--blackBaseColor), 600;
}
