@define-mixin alertSectionGridLayout {
    display: grid;
    gap: 4px 16px;
    grid-template-columns: auto 1fr;
    align-items: baseline;

    & .Body {
        grid-column: 2 / 3;
    }
}

.AlertSection {
    @mixin alertSectionGridLayout;
    padding: 16px 16px 24px;
}

.Header {
    @mixin p30;
    font-family: var(--serifFontFamily);
    font-weight: 600;
    margin: 0;
}

.Body {
    @mixin p40;
    margin-bottom: 0;

    @media (--tablet) {
        @mixin p20;
    }
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
}

.Variant--small {
    gap: 4px 8px;

    & .Icon {
        @mixin fixedRatioSvgIcon 16px;
        top: 2px;
    }
}

.Variant--medium {
    & .Icon {
        @mixin fixedRatioSvgIcon 24px;
        top: 4px;
    }
}

.Variant--large {
    & .Icon {
        @mixin fixedRatioSvgIcon 24px;
        top: 2px;
    }

    & .Header {
        @media (--tablet) {
            @mixin h40;
        }
    }
}

.Variant--noHeader {
    align-items: center;

    @media (--tablet) {
        padding-bottom: 16px;
    }

    & .Icon {
        top: 0;
    }
}

.Variant--info {
    background-color: var(--notificationFocusLighten60Color);
}

.Variant--success {
    background-color: var(--notificationSuccessLighten60Color);
}

.Variant--warning {
    background-color: var(--notificationWarningLighten60Color);
}

.Variant--error {
    background-color: var(--notificationErrorLighten60Color);
}
