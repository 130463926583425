@define-mixin linkVariant $underlineColor, $hoverUnderlineColor {
    &:hover {
        & .Label {
            border-bottom-color: $hoverUnderlineColor;
        }
    }

    & .Label {
        border-bottom: 2px solid $underlineColor;
    }
}

.Link {
    @mixin clearLink;
    display: inline-block;

    &.Variant--button {
        display: inline-flex;

        & .Label {
            @mixin centerElements;
            @mixin hyphenateWords;
        }
    }
}

.Variant--default,
.Variant--clear {
    @mixin onFocus {
        @mixin focusVisible 5px;
    }
}

.Variant--button.Variant--button.Variant--button,
.Variant--default {
    & .ExternalIcon {
        @mixin fixedRatioSvgIcon 16px;
    }
}

.Variant--default {
    @mixin linkVariant var(--primaryHKBaseColor), var(--primaryHKLighten40Color);

    & .Label {
        @mixin linkAnimation;
        @mixin hyphenateWords;
        display: inline;

        & > * {
            font-size: inherit;
            line-height: inherit;
        }
    }

    & .ExternalIcon.ExternalIcon.ExternalIcon {
        display: inline-block;
        margin-left: 8px;
    }
}

.Variant--primaryVariant {
    @mixin linkVariant var(--primaryHKLighten40Color), var(--primaryHKLighten60Color);
}

.Variant--secondaryVariant {
    @mixin linkVariant var(--support02HKDarken60Color), var(--support02HKDarken40Color);
}

.Variant--lightBlackVariant {
    @mixin linkVariant var(--blackLighten20Color), var(--blackLighten40Color);
}
