@define-mixin wrapper {
    margin-right: auto;
    margin-left: auto;
    padding: 0 var(--wrapperHorizontalPadding);
    width: 100%;
    max-width: var(--wrapperWidth);
}

@define-mixin extendedWrapperVariables {
    --wrapperWidth: 1920px;

    @media (--desktopHD) {
        --wrapperHorizontalPadding: 80px;
        --layoutGridOffset: 2;
        --layoutGridSpan: 8;
    }
}

@define-mixin myPageWrapperVariables {
    --wrapperWidth: 1440px;
}

@define-mixin resetWrapper {
    --wrapperWidth: unset;
    --wrapperHorizontalPadding: 0;
}
