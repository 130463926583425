@define-mixin clearLink {
    display: block;
    color: inherit;
    border-bottom: none;
    margin: 0;
}

@define-mixin linkAnimation {
    transition: border-color 0.1s ease-out;
}

@define-mixin linkContainerOverflowHidden {
    margin: 6px;
}

@define-mixin linkUnderline $color, $hoverColor {
    border-bottom: 2px solid $color;

    &:hover {
        border-bottom-color: $hoverColor;
    }
}

@define-mixin link {
    @mixin linkAnimation;
    @mixin linkUnderline var(--primaryHKBaseColor), var(--primaryHKLighten40Color);

    @mixin onFocus {
        @mixin focusVisible 4px;
    }
}
