.ComboSelectorButtonGroup {
    display: flex;
    gap: 16px;
}

.Button {
    &:not(.Button--selected) {
        padding: 6px 30px !important;
    }
}
