.ScrollLock--enabled {
    position: fixed;
    overflow: hidden scroll !important;
    width: 100%;

    /* https://bugzilla.mozilla.org/show_bug.cgi?id=1676711 */
    @media (--firefox) {
        position: initial;
        overflow: hidden !important;
    }
}

.ScrollLock--hiddenScrollBar {
    overflow: hidden !important;
}
