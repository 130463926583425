.Content {
    @mixin collapseAnimation;
}

.ContentInner {
    width: 100%;
}

.Variant--solid {
    & .ContentInner {
        padding: 24px 0;

        @media (--tablet) {
            padding: 24px;
        }
    }

    &.Variant--dark {
        background-color: var(--support02HKBaseColor);

        & .Content {
            background-color: var(--support02HKLighten20Color);
        }
    }

    &.Variant--light {
        background-color: var(--whiteBaseColor);

        & .Content {
            background-color: var(--blackLighten60Color);
        }
    }

    &.Variant--white {
        background-color: var(--whiteBaseColor);
    }

    &.Variant--darkGrey {
        background-color: var(--blackLighten40Color);

        & .Content {
            background-color: var(--whiteBaseColor);
        }
    }

    &.Variant--grey {
        background-color: var(--blackLighten60LegacyColor);

        & .Content {
            border-top: 1px solid var(--blackLighten50LegacyColor);
        }

        &.Variant--hidden {
            & .Content {
                border-top: none;
            }
        }
    }
}

.Variant--outline {
    & .ContentInner {
        padding: 24px 0;
    }

    &.Variant--dark,
    &.Variant--grey {
        & .Content {
            background-color: unset;
        }
    }

    &.Variant--light,
    &.Variant--white {
        color: var(--whiteBaseColor);

        & .Content {
            background-color: unset;
        }
    }
}

.Variant--none {
    & .ContentInner {
        padding: 0;
    }
}

.Variant--fullWidth {
    & .ContentInner {
        padding: 24px 32px;
    }
}

.Variant--withBoxShadow {
    @mixin themeBoxShadowSmall;
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;

    & .Content {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.Variant--clear {
    /* todo. Move here from main styles (where this variant is defined as default or remove the type if no longer needed). */
}

.Variant--darkGrey {
    /* todo. Move here from main styles (where this variant is defined as default or remove the type if no longer needed). */
}

.Variant--default {
    /* todo. Move here from main styles (where this variant is defined as default or remove the type if no longer needed). */
}
