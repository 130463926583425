@define-mixin inputCircleVariant $outerColor, $outerCheckAndHoverColor, $innerColor, $disabledColor {
    & .Label::before {
        border-color: $outerColor;
    }

    & .Input:checked + .LabelOuter .Label::before,
    & .LabelOuter:hover .Label::before {
        border-color: $outerCheckAndHoverColor;
    }

    & .Input:checked + .LabelOuter .Label::after {
        background-color: $innerColor;
    }

    & .Input:disabled,
    & .Input:disabled:hover {
        & + .LabelOuter .Label::before {
            border-color: $disabledColor;
        }
    }

    & .Input:checked:disabled,
    & .Input:checked:disabled:hover {
        & + .LabelOuter .Label::after {
            background-color: $disabledColor;
        }
    }
}

@define-mixin textVariant $color, $disabledColor, $checkedColor {
    & .Label {
        color: $color;
    }

    & .Input:checked + .LabelOuter .Label {
        color: $checkedColor;
    }

    & .Input:disabled,
    & .Input:disabled:hover {
        & + .LabelOuter .Label {
            color: $disabledColor;
        }
    }
}

.Radio {
    display: flex;
}

.Input {
    @mixin visuallyHidden;

    @mixin onFocus {
        & + .LabelOuter {
            @mixin focusVisible;
        }
    }

    &:disabled,
    &:disabled:hover {
        & + .LabelOuter {
            cursor: default;
        }
    }
}

.LabelOuter {
    width: 100%;
    padding: 16px;
    cursor: pointer;
}

.Label {
    @mixin radio;
    display: block;
    color: var(--blackLighten10Color);
    transition: color 0.2s ease-out;
    padding-left: 2.7em;

    &::before {
        top: 0.05em;
        left: 0;
    }

    &::after {
        top: 0.45em;
        left: 0.4em;
    }
}

.Variant--inheritFont {
    & .Label {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
    }
}

.Variant--initialFont {
    & .Label {
        @mixin p20;
        font-weight: 400;
    }
}

.Variant--default {
    @mixin textVariant var(--blackLighten10Color), var(--blackLighten30Color);
    @mixin inputCircleVariant var(--blackLighten30Color), var(--blackLighten10Color), var(--blackLighten10Color),
        var(--blackLighten40Color);
}

.Variant--light {
    @mixin textVariant var(--whiteBaseColor), var(--blackLighten20Color);
    @mixin inputCircleVariant var(--blackLighten30Color), var(--whiteBaseColor), var(--whiteBaseColor),
        var(--blackLighten20Color);
}

.Variant--yellow {
    @mixin textVariant var(--whiteBaseColor), var(--blackLighten20Color), var(--support01HKDarken20Color);
    @mixin inputCircleVariant var(--blackLighten30Color), var(--whiteBaseColor), var(--support01HKDarken20Color),
        var(--blackLighten20Color);
}

.Variant--error {
    @mixin textVariant var(--blackLighten10Color), var(--blackLighten30Color);
    @mixin inputCircleVariant var(--notificationErrorBaseColor), var(--notificationErrorBaseColor),
        var(--notificationErrorBaseColor), var(--notificationErrorLighten60Color);
}
